import { EVENT_TYPE } from '../../../../common/enums';
import { parseCssDark } from '../../../../utils/common';

interface EventSummaryProps {
  isDark: boolean;
  summary: string;
  type: EVENT_TYPE;
  color?: string;
}

const EventSummary = (props: EventSummaryProps) => {
  const { isDark, summary, type, color } = props;
  return (
    <>
      <div className="animatedBackground">
        <p
          className={`Kalend__text ${parseCssDark(
            'Kalend__Event__summary',
            isDark
          )} Kalend__Event__summary__type-${type} slider`}
          style={{ backgroundColor: color }}
        >
          {summary}{' '}
        </p>
      </div>
    </>
  );
};

export default EventSummary;
