import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import Kalend, { CalendarView } from './kalend/src/index';
import './kalend/src/styles/main.scss';
import fr from '../locales/fr.json';

interface Data {
  datasetid: string;
  recordid: string;
  fields: {
    heure_arrivee: string;
    code_equip: string;
    date: string;
    train_no: string;
    destination: string;
    origine: string;
    entity: string;
    origine_iata: string;
    od_happy_card: 'OUI';
    axe: string;
    destination_iata: string;
    heure_depart: string;
  };
  record_timestamp: string;
}

const CalendComponent = (props: any) => {
  const [data, setData] = useState([{}]);
  const [origine, setOrigine] = useState(localStorage.getItem('origine') || '');
  const [destination, setDestination] = useState(
    localStorage.getItem('destination') || ''
  );

  // Create and load demo events
  useEffect(() => {
    if (props.origine?.length > 0) {
      setOrigine(props.origine);
      localStorage.setItem('origine', props.origine);
    }
    if (props.destination?.length > 0) {
      setDestination(props.destination);
      localStorage.setItem('destination', props.destination);
    }
    if (destination.length === 0 || origine.length === 0) {
      return;
    }
    Promise.all([
      fetch(
        `https://data.sncf.com/api/records/1.0/search/?dataset=tgvmax&rows=-1&q=&sort=-date&facet=date&facet=origine&facet=destination&facet=od_happy_card&refine.od_happy_card=OUI&refine.destination=${destination}&refine.origine=${origine}`
      )
        .then((response) => response.json())
        .then((data) => {
          const records: Array<Data> = data.records;
          const aller: any = [];
          for (let i = 0; i < records.length; i++) {
            const x: Data = records[i];
            aller.push({
              id: x.recordid,
              startAt: x.fields.date + 'T' + x.fields.heure_depart,
              endAt: x.fields.date + 'T' + x.fields.heure_arrivee,
              title: x.fields.heure_depart,
              summary:
                x.fields.heure_depart +
                ' ' +
                x.fields.origine.replace(/ *\([^)]*\) */g, '') +
                ' -> ' +
                x.fields.destination.replace(/ *\([^)]*\) */g, '') +
                ' | Train n°' +
                x.fields.train_no,
              color: '#04ba0a',
              allDay: false,
              originDate: x.record_timestamp,
            });
          }
          // console.log('aller', aller);
          return aller;
        }),
      fetch(
        `https://data.sncf.com/api/records/1.0/search/?dataset=tgvmax&q=&rows=-1&sort=-date&facet=date&facet=origine&facet=destination&facet=od_happy_card&refine.origine=${destination}&refine.destination=${origine}&refine.od_happy_card=OUI`
      )
        .then((response) => response.json())
        .then((data) => {
          const records: Array<Data> = data.records;
          const retour: any = [];
          for (let i = 0; i < records.length; i++) {
            const x: Data = records[i];
            retour.push({
              id: x.recordid,
              startAt: x.fields.date + 'T' + x.fields.heure_depart,
              endAt: x.fields.date + 'T' + x.fields.heure_arrivee,
              summary:
                x.fields.heure_depart +
                ' ' +
                x.fields.origine +
                ' -> ' +
                x.fields.destination +
                ' | Train n°' +
                x.fields.train_no,
              color: '#f54242',
              allDay: false,
              originDate: x.record_timestamp,
            });
          }
          // console.log('retour', retour);
          return retour;
        }),
    ]).then((res) => {
      let all = [...res[0], ...res[1]];
      // sort
      all = all.sort((a, b) => {
        return a.startAt.localeCompare(b.startAt);
      });

      all = all.filter((x) => {
        return x.startAt.localeCompare(DateTime.local().toISO()) > 0;
      });
      setData(all);
    });
  }, []);

  useEffect(() => {
    if (props.origine) {
      setOrigine(props.origine);
      localStorage.setItem('origine', props.origine);
    }
    if (props.destination) {
      setDestination(props.destination);
      localStorage.setItem('destination', props.destination);
    }
  }, [props.origine, props.destination]);

  const onNewEventClick = (data: any) => {
    const msg = `New event click action\n\n Callback data:\n\n${JSON.stringify({
      hour: data.hour,
      day: data.day,
      event: 'click event ',
    })}`;
    console.log(msg);
  };

  // Callback for event click
  const onEventClick = (data: any) => {
    const msg = `Click on event action\n\n Callback data:\n\n${JSON.stringify(
      data
    )}`;
    console.log(msg);
  };
  return (
    <Kalend
      kalendRef={props.kalendRef}
      onNewEventClick={onNewEventClick}
      initialView={CalendarView.MONTH}
      disabledViews={[CalendarView.DAY]}
      onEventClick={onEventClick}
      events={data}
      initialDate={new Date().toISOString()}
      hourHeight={60}
      timezone={'Europe/Berlin'}
      onStateChange={props.onStateChange}
      selectedView={props.selectedView}
      customLanguage={fr}
      isDark={true}
    />
  );
};

export default CalendComponent;
