import { useContext, useEffect, useReducer } from 'react';
import { Config, EventStyle } from '../../common/interface';
import { Context, Store } from '../../context/store';
import { EVENT_TYPE } from '../../common/enums';
import { EventButtonProps } from './EventButton.props';
import { MONTH_EVENT_HEIGHT } from 'kalend-layout/constants';
import { eventButtonInitialState } from './EventButton.utils';
import { parseEventColor } from '../../utils/calendarDays';
import ButtonBase from '../buttonBase/ButtonBase';
import EventAgenda from './eventAgenda/EventAgenda';
import EventMonth from './eventMonth/EventMonth';
import EventNormal from './eventNormal/EventNormal';
import EventShowMoreMonth from './eventShowMoreMonth/EventShowMoreMonth';
import stateReducer from '../../utils/stateReducer';

const EventButton = (props: EventButtonProps) => {
  const { item, type } = props;
  const { event } = item;
  const { startAt } = event;

  const [state, dispatchState]: any = useReducer(
    stateReducer,
    eventButtonInitialState
  );
  const setState = (stateName: string, data: any): void => {
    const payload: any = { stateName, data };
    dispatchState({ state, payload });
  };

  const [store] = useContext(Context);

  const { config, callbacks } = store as Store;

  const { isDark } = config as Config;
  const { onEventClick } = callbacks;

  const eventColor: string = event.color
    ? parseEventColor(event.color as string, isDark)
    : 'indigo';

  const style: EventStyle = {
    position:
      type === EVENT_TYPE.AGENDA || type === EVENT_TYPE.SHOW_MORE_MONTH
        ? 'relative'
        : 'absolute',
    height:
      state.height !== null ? state.height : item.height || MONTH_EVENT_HEIGHT,
    width: state.width !== null ? state.width : item.width || '100%',
    top: state.offsetTop !== null ? state.offsetTop : item.offsetTop,
    left: state.offsetLeft !== null ? state.offsetLeft : item.offsetLeft,
    zIndex: state.zIndex || item.zIndex,
    border: state.zIndex > 2 ? `solid 1px white` : `solid 1px ${eventColor}`,
    backgroundColor: eventColor,
    visibility: 'visible',
    // alignItems: meta?.centerText ? 'center' : 'inherit',
  };

  const handleEventClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (onEventClick) {
      onEventClick(event, e);
    }
  };

  const setLayout = (layout: any) => {
    setState('initialTop', layout.offsetTop);
    setState('initialLeft', layout.offsetLeft);
    setState('offsetTop', layout.offsetTop);
    setState('offsetLeft', layout.offsetLeft);
    setState('drawingY', layout.offsetTop);
    setState('startAt', startAt);
    setState('width', layout.width);
    setState('height', layout.height);
    setState('zIndex', layout.zIndex);
    setState('border', layout.border);
    setState('meta', layout.meta);
  };

  useEffect(() => {
    setLayout(item);
    // initEventButtonPosition(type, props.day, event, store, setLayout, index);
  }, []);

  /**
   * Initial long press click/touch on event
   * @param e
   */
  const onMouseDown = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <ButtonBase
      id={event.id}
      isDark={isDark}
      style={style}
      className={`Kalend__Event-${type} ${
        state.isDragging ? 'Kalend__EventButton__elevation' : ''
      }`}
      onClick={handleEventClick}
      onMouseDown={onMouseDown}
      onTouchStart={onMouseDown}
    >
      {type === EVENT_TYPE.MONTH || type === EVENT_TYPE.HEADER ? (
        <EventMonth event={event} isDark={isDark} type={type} />
      ) : null}
      {type === EVENT_TYPE.NORMAL ? (
        <EventNormal
          event={event}
          isDark={isDark}
          type={type}
          meta={item.meta}
        />
      ) : null}
      {type === EVENT_TYPE.AGENDA ? (
        <EventAgenda event={event} isDark={isDark} type={type} />
      ) : null}
      {type === EVENT_TYPE.SHOW_MORE_MONTH ? (
        <EventShowMoreMonth event={event} isDark={isDark} type={type} />
      ) : null}
    </ButtonBase>
  );
};

export default EventButton;
