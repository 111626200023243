import React, { useEffect } from 'react';
import { Select } from 'antd';
import CalendComponent from '../components/Calendar';
import { Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import 'antd/dist/antd.css';
import { Row, Col } from 'antd';
const { Option } = Select;

const MainPage = () => {
  const [origine, setOrigine] = React.useState([]);
  const [destination, setDestination] = React.useState([]);
  const [selectedOrigine, setSelectedOrigine] = React.useState(
    localStorage.getItem('origine') || ''
  );
  const [selectedDestination, setSelectedDestination] = React.useState(
    localStorage.getItem('destination') || ''
  );
  const [key, setKey] = React.useState(0);

  useEffect(() => {
    Promise.all([
      fetch(
        'https://data.sncf.com/api/records/1.0/search/?dataset=tgvmax&q=&facet=date&facet=origine&facet=destination&facet=od_happy_card'
      )
        .then((response) => response.json())
        .then((data) => {
          const listingOrigin = data.facet_groups[1].facets;
          const listingDestination = data.facet_groups[2].facets;

          const origin = listingOrigin.map((x: any) => x.name);
          const destination = listingDestination.map((x: any) => x.name);

          setOrigine(
            origin
              .sort((a: any, b: any) => a.localeCompare(b))
              .map((x: string) => <Option key={x}>{x}</Option>)
          );

          setDestination(
            destination
              .sort((a: any, b: any) => a.localeCompare(b))
              .map((x: string) => <Option key={x}>{x}</Option>)
          );
        }),
    ]);
  }, []);

  const handleChangeOrigine = (value: any) => {
    setSelectedOrigine(value);
  };

  const handleChangeDestination = (value: any) => {
    setSelectedDestination(value);
  };

  const handleClick = () => {
    setKey(key + 1);
    console.log('origine', selectedOrigine);
    console.log('destination', selectedDestination);
    console.log('key', key);
  };

  return (
    <div className={'wrapper'}>
      <div className={'container__title'}>
        <Row
          style={{
            width: '100vw',
            rowGap: '0px',
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            justifyContent: 'center',
            alignItems: 'stretch',
          }}
          align="middle"
          wrap={true}
        >
          <Col span={8} style={{ marginBottom: '5px', marginRight: '10px' }}>
            <Row>
              {/* <small>Départ</small> */}
              <small>Gare 1</small>
            </Row>
            <Row>
              <Select
                showSearch
                onSelect={handleChangeOrigine}
                style={{ width: '100%' }}
                placeholder="Départ"
                optionFilterProp="children"
                defaultValue={selectedOrigine}
                filterOption={(input, option) =>
                  // @ts-ignore
                  option.children
                    // @ts-ignore
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  // @ts-ignore
                  optionA.children
                    // @ts-ignore
                    .toLowerCase()
                    // @ts-ignore
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {origine}
              </Select>
            </Row>
          </Col>
          <Col span={8} style={{ marginBottom: '5px', marginRight: '10px' }}>
            <Row>
              {/* <small>Arrivée</small> */}
              <small>Gare 2</small>
            </Row>
            <Row>
              <Select
                showSearch
                defaultValue={selectedDestination}
                onSelect={handleChangeDestination}
                style={{ width: '100%' }}
                placeholder="Arrivée"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  // @ts-ignore
                  option.children
                    // @ts-ignore
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                filterSort={(optionA, optionB) =>
                  // @ts-ignore
                  optionA.children
                    // @ts-ignore
                    .toLowerCase()
                    // @ts-ignore
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {destination}
              </Select>
            </Row>
          </Col>
          <Col span={2} style={{ marginBottom: '5px', marginRight: '10px' }}>
            <Row>
              <small>Go</small>
            </Row>
            <Row>
              <Button
                icon={<SearchOutlined />}
                style={{ width: '100%' }}
                onClick={handleClick}
              />
            </Row>
          </Col>
        </Row>
      </div>
      <div className={'Calendar__wrapper'}>
        <CalendComponent
          key={key}
          origine={selectedOrigine}
          destination={selectedDestination}
        />
      </div>
    </div>
  );
};

export default MainPage;
